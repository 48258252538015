<template>
  <div 
    v-loading="isLoadingMore || isLoadingDefault" 
    id="public-favorite" 
    class="padding-btm-channel-link"
  >
    <el-row
      :type="isMobile ? '' : 'flex'" 
      justify="space-between" 
      align="middle"
      class="breadcrumb-search-row"
    >
        <el-col :span="isMobile ? 24 : 12">
          <breadcrumb isPublic />
        </el-col>

        <el-col :span="isMobile ? 24 : 12">
          <table-action-row
            :filterList="listQuery"
            @onFilterChange="onSearchChange"
            :isPositionAbsolute="false"
          />
        </el-col>
    </el-row>

    <el-row 
      :type="isMobile ? '' : 'flex'" 
      justify="space-between" 
      class="result-summary-filter-row"
    >
      <span 
        class="article-num"
        :class="{ hidden: isLoadingDefault == true }"
      >
        <span v-if="!searchedKeyword">
          {{ $t("NUM_OF_ARTICLE", { num: totalAvailable }) }}
        </span>
        <span 
          v-else
          v-html="searchedKeyword ? $t('RESULT_SUMMARY', resultSummaryTransObj) : null" 
        />
      </span>
      <div>
        <span>{{ $t("FILTER_BY") }}</span>
        <el-select v-model="selectedCategoryId" @change="mountedFn">
          <el-option
            v-for="item in allCategory"
            :key="item.id"
            :label="item.label ? item.label : getNameFromTransList(item)"
            :value="item.id"
          />
        </el-select>
      </div>
    </el-row>

    <el-divider />

    <template v-if="totalAvailable == 0 && !isLoadingMore">
      <div class="no-result">{{ $t("NO_RESULT") }}</div>
    </template>

    <template v-else>
      <transition-group name="slide-from-right">
        <el-row 
          class="all-articles" 
          :type="isMobile ? '' : 'flex'" 
          justify="center" 
          v-for="(row, index) in allChunkedResult"
          :key="arbitraryId + 'row' + index"
          :gutter="isMobile ? 0 : 30"
        >
          <el-col 
            :span="isMobile ? 24 : 12"
            v-for="(el, index2) in row"
            :key="arbitraryId+'row'+index+'el'+index2"
          >
            <article-summary 
              v-if="el !== 'filling'"
              orientation="landscape"
              size="small"
              :articleObj="el"
              :key="'el'+el.id"
              hideName
              withWhiteBg withShadow
              clickable
              showFavoriteBtn
              shouldFavorited
              @refresh="() => removeArticle(el.id)"
            />
          </el-col>
        </el-row>
      </transition-group>

      <div id="page-bottom-to-load-more"/>
    </template>

  </div>
</template>

<script>
import ArticleSummary from "./components/article-summary.vue"
import TableActionRow from '@/components/table/table-action-row.vue'
import { mapState } from "vuex";
import debounce from 'lodash/debounce'
import { getAllArticleInteraction } from "@/api/article"
import { getAllCategory } from "@/api/category"
import { generalMixin } from "@/utils/general-mixin.js"
import { splitArrayToChunk, escapeHtml } from "@/utils/helpers"
import Breadcrumb from '@/components/breadcrumb.vue';

export default {
  name: "ArticleByCategory",
  components: {
    ArticleSummary,
    Breadcrumb,
    TableActionRow,
  },
  computed: {
    ...mapState("app", ["isMobile"]),
    resultSummaryTransObj() {
      return {
        totalLoaded: this.totalLoaded,
        totalAvailable: this.totalAvailable,
        keyword: escapeHtml(this.searchedKeyword),
      }
    }
  },
  mixins: [ generalMixin ],
  data() {
    return {
      searchedKeyword: "",
      listQuery: {},
      allResult: [],
      allChunkedResult: [],
      totalLoaded: 0,
      totalAvailable: 1,
      selectedCategoryId: null,
      isLoadingMore: false,
      isLoadingDefault: false,
      allCategory: [],
      arbitraryId: 0, // to refresh ALL
    }
  },
  methods: {
    async getArticle(amount = 1) {
      let offset = this.totalLoaded
      const postData = {
        offset,
        limit: amount,
        search: this.listQuery.search,
        articleInteraction: {
            actionType: "FAVOURITE"
        }
      }
      if (this.selectedCategoryId) {
        postData.category = { id: this.selectedCategoryId }
      }
      this.searchedKeyword = this.listQuery.search
      let result = []
      this.listQuery = postData

      await getAllArticleInteraction(this.listQuery).then((res) => {
        if (res.result?.list != null) {
          result = res.result.list.map((el) => el.article)
          this.totalAvailable = res.result.total
          this.totalLoaded += result.length
        }
      })
      return result
    },
    removeArticle(id) {
        this.arbitraryId++
        this.allResult = this.allResult.filter((el) => el.id != id)
        this.refreshRow()
    },
    onScroll: debounce(function () {
      const tolerance = 900 // load before reaching
      const idYPosition = document.getElementById("page-bottom-to-load-more").getBoundingClientRect().top;
      if (idYPosition - tolerance < 0) {
        if (this.totalAvailable > this.totalLoaded && !this.isLoadingDefault) {
          this.loadMore()
        }
      }
    }, 100),
    async loadMore() {
      if (this.isLoadingMore) return
      const loadNum = 10

      this.isLoadingMore = true
      const moreResult = await this.getArticle(loadNum)
      this.allResult.push(...moreResult) 

      this.refreshRow()

      this.isLoadingMore = false
    },
    onSearchChange(value) {
      if (
        !this.searchedKeyword && !value.search
      ) {
        return
      }

      this.listQuery = value
      this.mountedFn() 
    },
    refreshRow() {
        this.allChunkedResult = []
        if (this.allResult.length === 0) return
    
        splitArrayToChunk(this.allResult, 2).forEach(row => {
            if (row.length === 1) row.push("filling")
            this.allChunkedResult.push(row)
        })
    },
    reset() {
      this.allResult = []
      this.allChunkedResult = []
      this.totalAvailable = 1
      this.totalLoaded = 0
      this.arbitraryId++
    },
    getCategory() {
      const postData = {
        genericRecord: {
          status: "ACTIVE",
          type: "category"
        }
      }
      getAllCategory(postData).then(res => {
        if (res.result) {
          this.allCategory = res.result.list
          this.allCategory.unshift({
            id: null,
            label: this.$t("ALL")
          })
        }
      })
    },
    async mountedFn() {
        this.reset()
        this.isLoadingDefault = true
        await this.loadMore()
        this.isLoadingDefault = false
    },
  },
  async mounted() {
    this.getCategory()
    this.mountedFn()
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";

#public-favorite {
  .article-num { 
    font-weight: bold;
  }

  .breadcrumb-search-row {
    #breadcrumb {
        padding-left: 0;
        padding-bottom: 0;
        height: $breadcrumbHeight;
    }
  }

  .result-summary-filter-row {
    margin: 2rem 0 3rem 0;
    .el-select {
      margin-left: 1rem;
    }
    .hidden {
        visibility: hidden;
    }
  }

  .no-result {
    font-size: 2rem;
    font-weight: bold;
    margin: auto;
    height: 25vh;
    width: 100%;
    width: 100vw;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
  }
  .search-keyword-row {
    span {
      margin: auto;
      white-space: nowrap;
    }
    .el-divider {
      margin: 1.7rem 0.5rem;
    }
  }
  .all-articles {
    >* {
      margin: 1rem 0;
      width: 100%;
    }
  }
  .load-more {
    font-size: 18px;
    line-height: 98px;
  }

  // Transition
  .row-enter-active, .row-leave-active {
    transition: all 1s;
  }
  .row-enter, .row-leave-to /* .row-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
  }
}

.mobile {
  #public-favorite {
    .all-articles {
      >* {
        margin: 0;
        margin-bottom: 0.5rem;
      }
    }
    .result-summary-filter-row {
      margin-top: 0.5rem;
      .el-select {
        margin-top: 1rem;
      }
    }

    #breadcrumb {
      padding-left: 0;
      padding-bottom: 0;
      height: $breadcrumbHeightMobile - 20px;
    }
  }
}
</style>